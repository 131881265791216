
<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <div>
    <b-card title="Informations générales">
       <validation-observer ref="simpleRules">
        <b-form
          @submit.prevent
        >
        <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Nom"
              label-for="basicInput"
            >
             <validation-provider
              #default="{ errors }"
              name="Nom"
              rules="required"
            >
              <b-form-input
              required
                id="basicInput"
                v-model="form.name"
                :plaintext="readOnly"
                placeholder="Nom"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Photo"
              label-for="Photo"
            >
            <validation-provider
              #default="{ errors }"
              name="Photo"
              rules="required"
            >
            <b-media vertical-align="top" v-if="file!==null">
                  <template #aside>
                    <b-img
                      :src="file"
                      blank-color="#ccc"
                      width="64"
                      alt="placeholder"
                      
                    />
                  </template>
                  
                  <b-card-text class="mb-0">
                
                    <b-button
                    size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-warning"
                      class="btn-icon"
                      @click="editPhoto()"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                  </b-card-text>
                </b-media>
            <b-form-file v-else
            required
                v-model="file"
                
                placeholder="Choisissez ou faites glisser un fichier..."
                drop-placeholder="Déposer ici ..."
                @change="fileAdded($event)"
                :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Adresse 1"
              label-for="Prix"
            >
             <validation-provider
              #default="{ errors }"
              name="Adresse 1"
              rules="required"
            >
              <b-form-input
              required
                id="Prix"
                v-model="form.address1"
                :plaintext="readOnly"
                :state="errors.length > 0 ? false:null"
                placeholder="Adresse"
              />
               <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
           
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Adresse 2"
              label-for="Code"
            >
              <b-form-input
              required
                id="Code"
                v-model="form.address2"
                :plaintext="readOnly"
              
                placeholder="Adresse 2"
              />
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Ville"
              label-for="Code"
            >
             <validation-provider
              #default="{ errors }"
              name="Ville"
              rules="required"
            >
              <b-form-input
              required
                id="Code"
                v-model="form.city"
                :plaintext="readOnly"
                :state="errors.length > 0 ? false:null"
                placeholder="Ville"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Code postal"
              label-for="Code"
            >
            <validation-provider
              #default="{ errors }"
              name="Code postal"
              rules="required"
            >
              <b-form-input
              required
                id="Code"
                v-model="form.postcode"
                :plaintext="readOnly"
                :state="errors.length > 0 ? false:null"
                placeholder="Code postal"
              />
               <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Pays"
              label-for="Code"
            >
             <validation-provider
              #default="{ errors }"
              name="Pays"
              rules="required"
            >
              <b-form-input
              required
                id="Code"
                v-model="form.country"
                :plaintext="readOnly"
                :state="errors.length > 0 ? false:null"
                placeholder="Pays"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Téléphone"
              label-for="Code"
            >
            <validation-provider
              #default="{ errors }"
              name="Téléphone"
              rules="required"
            >
              <b-form-input
              required
                id="Code"
                v-model="form.phone"
                :plaintext="readOnly"
                :state="errors.length > 0 ? false:null"
                placeholder="Téléphone"
              />
               <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Email"
              label-for="Code"
            >
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-input
              required
                id="Code"
                v-model="form.email"
                :plaintext="readOnly"
                :state="errors.length > 0 ? false:null"
                placeholder="Email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Lunette"
              label-for="Texture"
            >
            <validation-provider
              #default="{ errors }"
              name="Lunette"
              rules="required"
            >
              <v-select
                v-model="form.glass"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :state="errors.length > 0 ? false:null"
                :options="glassChoices"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      </validation-observer>

    </b-card>
  <b-button v-if="edition==false"
     @click="createStockist"
    >
    Enregistrer
    </b-button>

    <b-button v-else
     @click="editStockist"
    >
    Enregistrer
    </b-button>
  </div>
</b-overlay>
</template>

<script>
import { BButton,BFormInput,BOverlay, BForm, BRow, BCol, BFormGroup,BFormFile, BCard, BFormCheckbox, BFormTextarea,BCardText, BMedia, BImg} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {APIRequest,Utils} from '@simb2s/senseye-sdk'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  name: 'StockistForm',
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    BCardText, 
    BOverlay,
    BMedia, 
    BImg,
    BRow,
    BCol,
    BFormFile,
    BCard,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BForm
  },
  directives: {
    Ripple,
  },
  data() {
      return {
          currentBase64:null,
          file: null,
          loading:true,
          edition:false,
          glasses:[],
          glassChoices:[],
          form:{
            name:"",
            address1:'',
            address2:'',
            postcode:'',
            city:'',
            country:'',
            glass:'',
            email:'',
            phone:'',
            photo:''
          },
      }
  },
  props: ['stockist', 'readOnly'],
  methods:{
    editPhoto() {
      this.file=null
    },
    async getGlasses() {
      let glasses = await APIRequest.list('glasses')
      this.glasses=glasses[0];
      this.glassChoices=Utils.formatSelect(this.glasses,'name','id')
      
    },
    async createStockist()
    {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
        this.loading=true
        if(this.form.glass!=null)
        {
          let glass_id = this.form.glass.value
          this.form.glass={}
          this.form.glass.id=glass_id
        }
        try {
        
          await APIRequest.create('stockists',this.form)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Le point de vente a bien été créé !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({name:'stockists'})
        } catch (error) {
          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }
        }
      })
      
    },
    async editStockist()
    {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
        this.loading=true
      if(this.form.glass!=null)
        {
          let glass_id = this.form.glass.value
          this.form.glass={}
          this.form.glass.id=glass_id
        }
        try {
        
          await APIRequest.update('stockists',this.stockist.id,this.form)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Le point de vente a bien été modifié !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({name:'stockists'})
        } catch (error) {
          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }
        }
      })
      
    },
    fileAdded(event)
    {
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {
          
          let b64=reader.result.split(';')
          let type=b64[0]
          if(type.includes('image'))
          {
              let base64=b64[1]
              //base64.replace('base64,','')
              this.currentBase64=reader.result
              this.file=this.currentBase64
              this.form.photo=this.currentBase64
          }
          else
          {
              this.file=null
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Ceci n'est pas une image !",
                  icon: 'AlertCircleIcon',
                  variant: 'warning',
                },
              })
          }
          
      };
    },
     
      
  },
  async mounted(){
    await this.getGlasses()
    if(this.stockist!=null)
    {
      this.form=this.stockist
      this.file=this.stockist.photo
      if(this.stockist.glass!=null)
      {
        for(let i=0;i<this.glassChoices.length;i++)
        {
          if(this.stockist.glass.id == this.glassChoices[i].value)
          {
            this.stockist.glass = this.glassChoices[i]
          }
        }
      }
      this.edition=true
    }
    this.loading=false
  }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>