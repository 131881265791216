var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[_c('div',[_c('b-card',{attrs:{"title":"Informations générales"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Nom","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"required":"","id":"basicInput","plaintext":_vm.readOnly,"placeholder":"Nom","state":errors.length > 0 ? false:null},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Photo","label-for":"Photo"}},[_c('validation-provider',{attrs:{"name":"Photo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.file!==null)?_c('b-media',{attrs:{"vertical-align":"top"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-img',{attrs:{"src":_vm.file,"blank-color":"#ccc","width":"64","alt":"placeholder"}})]},proxy:true}],null,true)},[_c('b-card-text',{staticClass:"mb-0"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon",attrs:{"size":"sm","variant":"outline-warning"},on:{"click":function($event){return _vm.editPhoto()}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1)],1)],1):_c('b-form-file',{attrs:{"required":"","placeholder":"Choisissez ou faites glisser un fichier...","drop-placeholder":"Déposer ici ...","state":errors.length > 0 ? false:null},on:{"change":function($event){return _vm.fileAdded($event)}},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Adresse 1","label-for":"Prix"}},[_c('validation-provider',{attrs:{"name":"Adresse 1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"required":"","id":"Prix","plaintext":_vm.readOnly,"state":errors.length > 0 ? false:null,"placeholder":"Adresse"},model:{value:(_vm.form.address1),callback:function ($$v) {_vm.$set(_vm.form, "address1", $$v)},expression:"form.address1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Adresse 2","label-for":"Code"}},[_c('b-form-input',{attrs:{"required":"","id":"Code","plaintext":_vm.readOnly,"placeholder":"Adresse 2"},model:{value:(_vm.form.address2),callback:function ($$v) {_vm.$set(_vm.form, "address2", $$v)},expression:"form.address2"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Ville","label-for":"Code"}},[_c('validation-provider',{attrs:{"name":"Ville","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"required":"","id":"Code","plaintext":_vm.readOnly,"state":errors.length > 0 ? false:null,"placeholder":"Ville"},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Code postal","label-for":"Code"}},[_c('validation-provider',{attrs:{"name":"Code postal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"required":"","id":"Code","plaintext":_vm.readOnly,"state":errors.length > 0 ? false:null,"placeholder":"Code postal"},model:{value:(_vm.form.postcode),callback:function ($$v) {_vm.$set(_vm.form, "postcode", $$v)},expression:"form.postcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Pays","label-for":"Code"}},[_c('validation-provider',{attrs:{"name":"Pays","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"required":"","id":"Code","plaintext":_vm.readOnly,"state":errors.length > 0 ? false:null,"placeholder":"Pays"},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Téléphone","label-for":"Code"}},[_c('validation-provider',{attrs:{"name":"Téléphone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"required":"","id":"Code","plaintext":_vm.readOnly,"state":errors.length > 0 ? false:null,"placeholder":"Téléphone"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"Code"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"required":"","id":"Code","plaintext":_vm.readOnly,"state":errors.length > 0 ? false:null,"placeholder":"Email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Lunette","label-for":"Texture"}},[_c('validation-provider',{attrs:{"name":"Lunette","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","state":errors.length > 0 ? false:null,"options":_vm.glassChoices},model:{value:(_vm.form.glass),callback:function ($$v) {_vm.$set(_vm.form, "glass", $$v)},expression:"form.glass"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1),(_vm.edition==false)?_c('b-button',{on:{"click":_vm.createStockist}},[_vm._v(" Enregistrer ")]):_c('b-button',{on:{"click":_vm.editStockist}},[_vm._v(" Enregistrer ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }